// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.

// Label to show current environment
.env-label {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.2em;
  text-align: center;
  background: gold;
  opacity: 0.6;
  pointer-events: none;
}

// Limit the app-viewport to make it look like a 'mobile app'
@media (min-width: 500px) and (min-height: 800px) {
  body {
    background: #333;
  }
  ion-app {
    background: #fff;
    margin: auto auto;
    border-radius: 0.66em;

    // Limit to a readable line-width
    max-width: 500px;

    // The width/height ratio should at least look 'portait'
    min-height: 700px;
    max-height: 80vh;
  }
}
