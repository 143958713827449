// Overriding `variables.scss`:
:root {
  --ion-font-family-body: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --ion-font-family-header: var(--ion-font-family-body);

  /** secondary **/
  --ion-color-secondary: #01adef;
  --ion-color-secondary-rgb: 1, 173, 239;
  --ion-color-secondary-contrast: #fff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0198d2;
  --ion-color-secondary-tint: #1ab5f1;

  /** dark **/
  --ion-color-dark: #fff;
  --ion-color-dark-rgb: 255, 255, 255;
  --ion-color-dark-contrast: rgb(40, 47, 59);
  --ion-color-dark-contrast-rgb: 40, 47, 59;
  --ion-color-dark-shade: #232934;
  --ion-color-dark-tint: #3e444f;
}

// Overriding `global.scss`:
.action {
  --action--color: #fff;
  --action--color-active: #fdc300;
  --action--background-color: #fdc300;
  --action--background-color-active: #fff;
}
