// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
// @import '~@ionic/angular/css/typography.css';
// @import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
// @import '~@ionic/angular/css/float-elements.css';
// @import '~@ionic/angular/css/text-alignment.css';
// Include only one used from above...
.ion-text-center {
  text-align: center !important;
}
// @import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// Custom Fonts

// Workaround for touch-screen laptops
// See: https://github.com/ionic-team/ionic-framework/issues/19942#issuecomment-621944260
// See: https://github.com/ionic-team/ionic-framework/blob/4.11.x/core/src/components/app/app.scss
html.plt-mobile ion-app {
  user-select: auto;
}

//
// Layout Utilities:
//
.list-flat {
  padding: 0;

  > li {
    list-style: none;
  }
}

.tile-grid {
  --column-gap: 0.25rem;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: flex-start;
  margin-left: calc(-2 * var(--column-gap));
  margin-right: calc(-2 * var(--column-gap));

  .tile-grid--item {
    padding-left: var(--column-gap);
    padding-right: var(--column-gap);
    margin-bottom: 1rem;
  }

  &.tile-grid_base-2 {
    .tile-grid--item {
      flex: 0 0 50%;
    }
  }
  &.tile-grid_base-3 {
    .tile-grid--item {
      flex: 0 0 33%;
    }
  }
}

//
// Text Style Utilities:
//
.text-style--header {
  font-family: var(--ion-font-family-header);
  font-weight: bold;
  letter-spacing: 0.08em;
}
.text-style--size-1 {
  font-size: 1rem;
}
.text-style--wrap-newlines {
  white-space: pre-line;
}
.text-style--hide-overflow_4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-height: 1.5;
  max-height: calc(4 * 1.5em);
  overflow: hidden;
}

.skeleton-text--light {
  --background-rgb: var(--ion-color-dark-contrast-rgb);
}

//
// Components
//
.action {
  // v2:
  // --action--color: #fff;
  // --action--color-active: #fff;
  // --action--background-color: #1f3958;
  // --action--background-color-active: #3a4f6a;
  --action--color: #011e60;
  --action--color-active: #011e60;
  --action--background-color: #fff;
  --action--background-color-active: #efefef;

  display: block;
  width: 100%;
  min-height: 3em;
  padding: 0.5em;

  border-radius: 0.25rem;
  text-decoration: none;

  &:link,
  &:visited {
    color: var(--action--color);
    background-color: var(--action--background-color);
  }

  &:hover,
  &:focus {
    color: var(--action--color-active);
    background-color: var(--action--background-color-active);
  }
}

.sheet--button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
}

.icon {
  &.icon_small {
    width: 0.8125em;
    height: 0.8125em;
    margin-inline-end: 0.25em;
  }
}

.list-item {
  display: flex;

  .list-item--icon {
    flex: 0 0 auto;
    margin-inline-end: 0.5rem;
  }
  .list-item--content {
    flex: 1 1 100%;
  }
}

.logo-badge {
  display: flex;
  width: 6.5rem;
  height: 6.5rem;
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.25rem;

  .logo-badge--image {
    width: 100%;
    height: 100%;
  }
}
